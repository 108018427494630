export default {
	yuebao: {
		0: "財務餘額",
		1: "利息總額",
		2: "上次",
		3: "轉入",
		4: "轉出",
		5: "換乘",
		6: "質押",
		7: "買漲",
		8: "買跌",
		9: "確認",
		10: "Recharge Voucher",
		11: "Change Pay Password",
		12: "Current Password",
		13: "New Password",
		14: "Confirm Password",
	},
	addnew: {
		homeNotice: "開啟您的數位貨幣之旅",
		market1: "硬幣",
		market2: "金屬",
		messageNotice: "尊敬的客戶，您好！ 請檢查您的新資訊.",
		messages: "我的留言",
		completed: "已完成",
		position: "持仓中",
		all: "全部",
		submit: "提交",
        realFirst: "請先進行身份認證",
        payFirst: "请先设置提款密码",
        password1: "修改登入密碼",
        password2: "修改提款密碼",
        paypassTit1: "請設定您的提款密碼",
        oldPass: "輸入原提款密碼",
        allView: "全部已读",
        confirmOrder: "确认订单",
        name: "名字",
        fee: "手续费",
        orderCycle: "选择时间",
        loan: "贷款",
        loanTit: "助力贷款",
        loanHostory: "贷款记录",
        labelProductTitle: "产品名字",
        immediateRepayment: "立即还款",
        loadAmount: "预期贷款金额",
        RepaymentCycle: "贷款偿还周期",
        day: "天",
        dailyRate: "日利率",
        repaymentMethod: "还款方式",
        lendingCompany: "贷款公司",
        loanTip: "信用贷款（请确保图片清晰可见）",
        loanTip1: "上传房产证书",
        loanTip2: "收入证明（雇佣关系）",
        loanTip3: "银行对账单详细信息",
        loanTip4: "上传身份证正面照片",
        interest: "利益",
        repaid: "已还款",
        unpaid: "未还款",
        tab1: "币币",
        tab2: "合约",
        tab3: "期权",
        available: "可用额度",
        pending: "锁定",
        equivalent: "折合",
        fundTransfer: "资金转移",
        flashExchange: "闪兑",
        select_breed: "选择币种",
        from: "从",
        to: "至",
        transferQuantity: "转移数量",
        repaymentMethod1:"到期一次还款"
    },
	addNew: {
		place_card_back: "点击上传身份证反面照",
		side1: "订单管理",
		side2: "修改密码",
		side3: "语言",
		side4: "退出登录",
		product: "产品",
		direction: "方向",
		cycle: "交易期间",
		long_buy: "做多买入",
		short_sell: "做空卖出",
		open_time: "开仓时间",
		close_time: "平仓时间",
		open_price: "开仓价格",
		close_price: "平仓价格",
		number: "数量",
		profit_loss: "盈亏",
		commission: "手续费",
		account_type: "账变类型",
		type1: "币币账户",
		type2: "法币账户",
		type3: "合约账户",
		type4: "期权账户",
		type5: "理财账户",
		message1: "请输入金额",
		message2: "账户余额不足",
		place_transPsd: "请输入交易密码",
		countpath: "倒计时结束自动跳转到订单页面",
		nowpath: "立即跳转",

	},
	billTypes: [
		{v: 101, name: '充值'},
		{v: 102, name: '存入'},
		{v: 103, name: '冻结'},
		{v: 104, name: '解冻'},
		{v: 105, name: '存入'},
		{v: 106, name: '存入'},
		{v: 201, name: '提现冻结'},
		{v: 202, name: '提现'},
		{v: 203, name: '提现成功'},
		{v: 204, name: '提现失败'},
		{v: 205, name: '提现手续费'},
		{v: 206, name: '转出'},
		{v: 207, name: '转入'},
		{v: 208, name: '币币转出'},
		{v: 209, name: '币币转入'},
		{v: 301, name: '币币手续费'},
		{v: 302, name: '合约收益'},
		{v: 303, name: '合约亏损'},
		{v: 311, name: '购买期权'},
		{v: 312, name: '期权收益'},
		{v: 313, name: '期权退回'},
		{v: 314, name: '期权手续费'},
		{v: 315, name: '币币买入冻结'},
		{v: 316, name: '币币买入扣除'},
		{v: 317, name: '币币买入返回'},
		{v: 318, name: '币币买入到账'},
		{v: 319, name: '币币买入到账'},
		{v: 320, name: '币币卖出冻结'},
		{v: 321, name: '币币卖出扣除'},
		{v: 322, name: '币币卖出返回'},
		{v: 323, name: '币币卖出到账'},
		{v: 324, name: '币币卖出到账'},
		{v: 325, name: '币币手续费'},
		{v: 401, name: '矿机加入'},
		{v: 402, name: '矿机返回'},
		{v: 403, name: '矿机收入'},
		{v: 404, name: '矿机退出'},
		{v: 405, name: '矿机退出手续费'},
		{v: 501, name: '盲盒购买'},
		{v: 502, name: '盲盒收入'},
	],
	common: {
		connectWallet: "连接钱包",
		app_name: "Coinbase",
		confirm: "确认",
		cancel: "取消",
		more: "更多",
		main: "主图",
		sub: "副图",
		empty: "暂无数据",
		success: "成功",
		copy_success: "复制成功！",
		copy_error: "复制失败！",
		passed: "已通过",
		rejectrd: "已拒绝",
		in_review: "审核中",
		nodata: "暂时没有内容",
		delete: "删除",
		add: "新增",
		buy: "买",
		sell: "卖",
		max: "最大",
		all: "全部",
	},
	home: {
		nav1: "分享",
		nav2: "钱包",
		nav3: "快讯",
		nav4: "教程",
		nav5: "客服",
		nav6: "挖矿",
		nav7: "公司",
		quick_p1: "快速充币",
		quick_p2: "支持TRC20-USDT、ERC20-USDT等",
		sort_tab1: "涨幅榜",
		sort_tab2: "24h成交量",
		sort_tit: "市场",
		sort_price: "最新价格",
		sort_rate: "24h涨跌幅",
		sort_vol: "成交量",

	},
	form: {
		place_username_email: "请输入用户名/邮箱",
		place_password: "请输入密码",
		place_confirm_password: "请再次输入密码",
		place_email: "请输入邮箱",
		send_email: "发送邮件",
		place_email_captcha: "请输入邮箱验证码",
		place_username: "请输入用户名",
		place_yzm: "请输入验证码",
		place_invite: "请输入邀请人ID",
		place_inner: "请输入内容",
		place_select: "请选择",
		label_oldpwd: "原登录密码",
		place_oldpwd: "请输入原登录密码",
		label_newpwd: "新登录密码",
		place_newpwd: "请输入新登录密码",
		label_crmpwd: "确认新登录密码",
		place_crmpwd: "请再次输入新登录密码",
		place_country: "请选择国家",
		place_name: "请输入您的姓名",
		place_card_number: "请输入您的身份证号或者护照号",
		place_card_main: "点击上传身份证正面照",
		label_currency: "货币",
		place_currency: "请选择货币",
		label_account_number: "账户号码",
		place_account_number: "请输入账户号码",
		message_buy_amount: "请输入购买金额",
		place_buy_tip: "至少买入",
	},

	foot: {
		nav1: "主页",
		nav2: "行情",
		nav3: "交易",
		nav4: "资产",
	},
	account: {
		login_title: "欢迎来到",
		register_title: "欢迎注册",
		contact: "点击联系客服",
		login: "登录",
		no_account: "还没有账号？",
		free_register: "免费注册",
		welcome_register: "欢迎注册",
	},
	asset: {
		asset: "资产",
		total_asset: "总资产",
		deposit: "充值",
		withdraw: "提现",
		available: "可用",
		freeze: "冻结",
		total: "总计",
		wallet: "钱包",
		select_link: "选择主链",
		deposit_address: "充值地址",
		deposit_tip: "请不要向这个地址充值除USDT之外的资产，任何充入此地址的其他资产将不可找回",
	},
	chart: {
		now: "当前",
		open_price: "开盘价",
		trans_amount: "成交金额",
		volumes: "成交量",
		height: "最高",
		low: "最低",
		buy: "买入",
		sell: "卖出",
		buy_more: "买多",
		sell_short: "卖空",
		buy_long: "做多",
		short_sell: "做空",
		spots: "现货",
		foreign_exchange: "外汇",
		market: "行情",
		order_tab1: "持仓",
		order_tab2: "挂单",
		order_tab3: "已平仓",
		surplus: "结余",
		available_balance: "可用余额",
		net_value: "净值",
		advance_charge: "预付款",
		unrealized_profits: "未实现盈利",
		open_price2: "开仓价格",
		now_price: "当前价格",
		lever_title: "调整杠杆",
		lever: "杠杆",
		check_price: "限价",
		market_price: "市价",
		number: "数量",
		total_amount: "总金额",
		price: "价格",
		now_entrust: "当前委托",
		history_entrust: "历史委托",
		holding_positions: "持有仓位",
		warehouse: "逐仓",
		margin: "保证金",
		from: "从",
		to: "至",
		pay: "支付",
		balance: "余额",
		get: "得到",
		flash_exchange: "闪兑",
		hand_number: "手数",
		registration_price: "挂单价",
		stop_profit: "止盈",
		stop_loss: "止损",
		need_margin: "需付保证金",
		current_price: "现价",
		direction: "方向",
		currency_trade: "货币交易",
		delivery_time: "交割时间",
		second: "秒",
		buy_amount: "买入金额",
		fee: "手续费",
		confirm_order: "确认订单",
		trade_history: "交易历史",
		turnover_number: "成交数量",
		turnovered: "已成交",
		opened: "待开仓",
		progress: "进行中",
		ended: "已结束",
		revoked: "已撤销",
		complete: "完成",
		time: "时间",
		operate: "操作",
		closing_position: "平仓",
		buy_price: "买入价",
	},
	market: {
		currency_trade: "货币交易",
		spots: "现货",
		contract: "合约",
		adjustment_accounts: "调账",
	},
	table: {
		market: "市场",
		rate: "24h涨跌幅",
		open_price: "开盘价",
		last_price: "最新价",
	},
	my: {
		modify_login_pwd: "修改登录密码",
		company: "公司",
		help_center: "帮助中心",
		history: "货币交易历史",
		buy: "买入",
		sell: "卖出",
		buy_price: "买入价格",
		type: "类型",
		amount: "金额",
		final_price: "最终价格",
		profit_loss: "盈亏",
		state: "状态",
		loss: "亏损",
		profit: "盈利",
		news: "快讯",
		authentication: "认证",
		service: "客服",
		asset: {
			amount_record: "金额记录",
			deposit_record: "充值记录",
			withdraw_record: "提现记录",
		},
		help: {
			p1: "随时随地为您提供服务",
			h1: "常见问题",
		},
		share: {
			share: "分享",
			invite_link: "邀请链接",
			copy_link: "复制链接",
		},
		wallet: {
			title: "钱包地址",
			account_number: "账户号码",
			add_address: "新增地址",

		}
	},
	finance: {
		finance_tit: "锁仓挖矿",
		finance_label1: "预计今日收益",
		finance_label2: "累计收益",
		finance_label3: "托管的订单",
		subscribe: "申购",
		day: "天",
		record: "记录",
		deposit_amount: "充值金额",
		received_amount: "到账金额",
		exchange_rate: "汇率",
		ad_p1: "挖矿赚不停",
		ad_p2: "锁仓挖矿是通过将usdt托管给平台超算力矿机在平台矿池中进行挖矿收益",
		join: "我要参与",
		ad_p3: "产品亮点",
		ad_p4: "举个例子",
		ad_tit1: "随存随取",
		ad_tit2: "每日下发",
		ad_tit3: "安全保障",
		ad_tit4: "收益不断",
		ad_tit5: "当天起息",
		ad_des1: "派息周期",
		ad_des2: "活期利息",
		ad_des3: "100%安全",
		ad_des4: "节假日收益",
		ad_des5: "成功存入后",
		ad_sub_tit1: "收益计算",
		ad_sub_tit2: "违约金",
		ad_sub_p1: "会员在平台锁仓10000U，选择了周期为5天，日产出为锁仓金额的0.3%~0.4%的理财产品，则每天产出如下：",
		ad_sub_p2: "最低：10000U x 0.3%=30 U",
		ad_sub_p3: "最高：10000U x 0.4%=40 U",
		ad_sub_p4: "即5天后可以获得150U~200U的收益，收益每日下发，下发的收益可随时存取，锁仓本金到期后，自动转入您的资产账户。",
		ad_sub_p5: "若您希望转出未到期的本金，则会产生违约金，违约金=违约结算比例*剩余天数*锁仓数量。",
		ad_sub_p6: "举例：该锁仓挖矿的违约结算比例为0.5%，剩余3天到期，锁仓数量为1000，则违约金=0.5%*3*1000=15U，实际退还本金为1000U-15U=985U",
	},

	city: {
		albania: "阿尔巴尼亚",
		algeria: "阿尔及利亚",
		argentina: "阿根廷",
		armenia: "亚美尼亚",
		australia: "澳大利亚",
		pakistan: "巴基斯坦",
		austria: "奥地利",
		bahrain: "巴林",
		belgium: "比利时",
		bosnia_and_Herzegovina: "波黑",
		brazil: "巴西",
		brunei: "文莱",
		bulgaria: "保加利亚",
		cambodia: "柬埔寨",
		canada: "加拿大",
		cameroon: "喀麦隆",
		chile: "智利",
		colombia: "哥伦比亚",
		costa_Rica: "哥斯达黎加",
		croatia: "克罗地亚",
		cyprus: "塞浦路斯",
		czech_Republic: "捷克",
		denmark: "丹麦",
		dominican_Republic: "多米尼亚共和国",
		egypt: "埃及",
		estonia: "爱沙尼亚",
		ethiopia: "埃塞俄比亚",
		finland: "芬兰",
		france: "法国",
		georgia: "格鲁吉亚",
		germany: "德国",
		ghana: "加纳",
		greece: "希腊",
		guyana: "圭亚那",
		honduras: "洪都拉斯",
		hong_Kong_China: "中国香港",
		hungary: "匈牙利",
		iceland: "冰岛",
		ireland: "爱尔兰",
		italy: "意大利",
		india: "印度",
		indonesia: "印度尼西亚",
		israel: "以色列",
		iran: "伊朗",
		iraq: "伊拉克",
		japan: "日本",
		kazakstan: "哈萨克斯坦",
		kenya: "肯尼亚",
		korea: "韩国",
		kuwait: "科威特",
		kyrgyzstan: "吉尔吉斯斯坦",
		laos: "老挝",
		latvia: "拉脱维亚",
		lithuania: "立陶宛",
		luxembourg: "卢森堡",
		macao_China: "中国澳门",
		macedonia: "马其顿",
		malaysia: "马来西亚",
		malta: "马耳他",
		mexico: "墨西哥",
		moldova: "摩尔多瓦",
		monaco: "摩纳哥",
		mongolia: "蒙古",
		montenegro: "黑山",
		morocco: "摩洛哥",
		myanmar: "缅甸",
		netherlands: "荷兰",
		new_Zealand: "新西兰",
		nepal: "尼泊尔",
		nigeria: "尼日利亚",
		norway: "挪威",
		oman: "阿曼",
		palestine: "巴勒斯坦",
		panama: "巴拿马",
		paraguay: "巴拉圭",
		peru: "秘鲁",
		philippines: "菲律宾",
		poland: "波兰",
		portugal: "葡萄牙",
		puerto_Rico: "波多黎各",
		qatar: "卡塔尔",
		romania: "罗马尼亚",
		russia: "俄罗斯",
		republic_of_Trinidad_and_Tobago: "特立尼达和多巴哥",
		rwanda: "卢旺达",
		saudi_Arabia: "沙特阿拉伯",
		serbia: "塞尔维亚",
		singapore: "新加坡",
		slovakia: "斯洛伐克",
		slovenia: "斯洛文尼亚",
		south_Africa: "南非",
		spain: "西班牙",
		sri_Lanka: "斯里兰卡",
		sweden: "瑞典",
		switzerland: "瑞士",
		taiwan_China: "中国台湾",
		tajikistan: "塔吉克斯坦",
		tanzania: "坦桑尼亚",
		thailand: "泰国",
		turkey: "土耳其",
		turkmenistan: "土库曼斯坦",
		ukraine: "乌克兰",
		united_Arab_Emirates: "阿联酋",
		united_Kingdom: "英国",
		united_States: "美国",
		uzbekistan: "乌兹别克斯坦",
		venezuela: "委内瑞拉",
		vietnam: "越南",
		afghanistan: "阿富汗",
		angola: "安哥拉",
		azerbaijan: "阿塞拜疆",
		bangladesh: "孟加拉国",
		belarus: "白俄罗斯",
		belize: "伯利兹",
		benin: "贝宁",
		bhutan: "不丹",
		bolivia: "玻利维亚",
		botswana: "博茨瓦纳",
		british_Virgin_Islands: "英属维京群岛",
		burkina_Faso: "布基纳法索",
		burundi: "布隆迪",
		cape_Verde: "佛得角",
		cayman_Islands: "开曼群岛",
		central_African_Republic: "中非共和国",
		chad: "乍得",
		comoros: "科摩罗",
		the_Republic_of_the_Congo: "刚果（布）",
		democratic_Republic_of_the_Congo: "刚果（金）",
		djibouti: "吉布提",
		ecuador: "厄瓜多尔",
		el_Salvador: "萨尔瓦多",
		equatorial_Guinea: "赤道几内亚",
		eritrea: "厄立特里亚",
		fiji: "斐济",
		gabon: "加蓬",
		gambia: "冈比亚",
		greenland: "格陵兰",
		guatemala: "危地马拉",
		guinea: "几内亚",
		haiti: "海地",
		isle_of_Man: "马恩岛",
		cote_d_Ivoire: "科特迪瓦",
		jamaica: "牙买加",
		jordan: "约旦",
		lebanon: "黎巴嫩",
		lesotho: "莱索托",
		liberia: "利比里亚",
		libya: "利比亚",
		madagascar: "马达加斯加",
		malawi: "马拉维",
		maldives: "马尔代夫",
		mali: "马里",
		mauritania: "毛里塔尼亚",
		mauritius: "毛里求斯",
		mozambique: "莫桑比克",
		namibia: "纳米比亚",
		nicaragua: "尼加拉瓜",
		republic_of_Niger: "尼日尔",
		north_Korea: "朝鲜",
		reunion: "留尼汪",
		san_Marino: "圣马力诺",
		senegal: "塞内加尔",
		sierra_Leone: "塞拉利昂",
		somalia: "索马里",
		sudan: "苏丹",
		suriname: "苏里南",
		eswatini: "斯威士兰",
		syria: "叙利亚",
		togo: "多哥",
		tonga: "汤加",
		tunisia: "突尼斯",
		united_States_Virgin_Islands: "美属维尔京群岛",
		uganda: "乌干达",
		uruguay: "乌拉圭",
		vatican: "梵蒂冈",
		yemen: "也门",
		yugoslavia: "南斯拉夫",
		zambia: "赞比亚",
		zimbabwe: "津巴布韦",
		china: "中国",
	}
};
